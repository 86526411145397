const SupplierLine = ({
  id,
  dilicom_id,
  supplier_name,
  latency,
  extra_cost
}) => {



  return (
    <div className="table-row">
      <div className="large">{supplier_name}</div>
      <div className="medium">{latency} jours</div>
      <div className="small">{extra_cost} €</div>
    </div>
  );
};

export default SupplierLine;
