import useFetch from "../api/useFetch";
import Loading from "./Loading";
import SupplierLine from "./SupplierLine";
import '../css/products.css'

const Suppliers = () => {
      const { result: suppliers, isLoading: suppliersLoading, error: suppliersError } = useFetch('/martelle_suppliers',[]);
      

  return (
    
    <>
  <div className="products">
  <div div className="title">
          FOURNISSEURS
        </div>
   <div className="table">
    <div className="table-header">
      <div className="large">Nom</div>
      <div className="medium">Delai Parametré</div>
      <div className="small">Extra Cout</div>
    </div>
    <div className="table-body">
            {suppliersLoading && <div className='table-placeholder'><Loading /></div>}
            {suppliersError && <div>{suppliersError}</div>}
            {!suppliersLoading &&
              suppliers &&
              suppliers.items.map((supplier) => (
                <SupplierLine
                id = {supplier.id}
                supplier_name = {supplier.name}
                latency = {supplier.latency}
                extra_cost = {supplier.extra_shipping_cost === null ? 0 : supplier.extra_shipping_cost}
                />
              ))}
          </div>
    </div>
   </div>
   </>
  )
}

export default Suppliers