import { useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SidebarContext from '../context/SidebarContext';
import SearchTermContext from '../context/SearchTermContext';
import AuthContext from '../context/AuthProvider';
import ProfilePicture from '../static/img/profile.jpg';


const Topbar = () => {
    const { collapsed } = useContext(SidebarContext);
    const { auth, logout } = useContext(AuthContext);
    const { setSearchTerm } = useContext(SearchTermContext);
    const navigate = useNavigate();
    const searchInputRef = useRef(null);

    const handleSearchSubmit = (e) => {
        e.preventDefault();
          setSearchTerm(searchInputRef.current.value);
          searchInputRef.current.value = '';
          navigate('/search');
      };
  
    return (
      <div className={`topbar ${collapsed ? 'collapsed' : ''}`}>
        <form className="search-container" onSubmit={handleSearchSubmit}>
          <input type="text" placeholder="Rechercher Commande..." ref={searchInputRef} />
          <button className="search-btn" type="submit">
            <i className="fa fa-search"></i>
          </button>
        </form>
        <div className="top-right">
          <p>
            {auth && `${auth.firstName} ${auth.lastName}`}
          </p>
          <img src={ProfilePicture} alt="" />
          <div className="icons">
            <div className="logout-icon">
              <Link to="/">
                <i className="fas fa-sign-out-alt" onClick={logout}></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };  

export default Topbar;
