import React from 'react';
import useFetch from '../api/useFetch';
const Stats = () => {

    const { result: fetchedStats, isLoading: fetchedStatsLoading, error: fetchedStatsError } = useFetch('/martelle_stats')
  return (
    <div div className="orders">
        <div div className="title"> STATISTIQUES </div>
      {fetchedStatsLoading && <p>Loading...</p>}
      {fetchedStatsError && <p>Error: {fetchedStatsError}</p>}
          <div className="table">
          <div className="table-header">
            <div className="table-cell">Année</div>
            <div className="table-cell">Mois</div>
            <div className="table-cell">Commandes</div>
            <div className="table-cell">Commandes facturées</div>
            <div className="table-cell">Total facturé</div>
          </div>
          <div className="table-body">
          {fetchedStats && fetchedStats.map((stat) => (
            <div className="table-row">
              <div className="table-cell">{stat.year}</div>
              <div className="table-cell">{stat.month}</div>
              <div className="table-cell">{stat.order_count}</div>
              <div className="table-cell">{stat.invoice_order_count}</div>
              <div className="table-cell">{stat.invoice_amount.toFixed(2)} €</div>
            </div>
          ))}
        </div>
        </div>
    </div>
  );
};

export default Stats;