const ShipmentLine = ({
    shipment_id,
    tracking_number,
    invoice_number,
    invoice_amount,
    weight,
    theoritical_weight,
    cost_per_kg,
    status,
    shipment_date,
    shipment_cost,
    label_url,
    shipment_currency,
    setInvoiceFormVisible,
    handleUpdateClick
  }) => {


  const openInvoiceForm = () => {
    setInvoiceFormVisible(true);
  };

  const handleInvoiceClick = (id) => {
    handleUpdateClick(id);
    openInvoiceForm();
  };
  return (
    <div className="table-row">
                <div className="date">{shipment_date}</div>
                <div className="small">{tracking_number}</div>
                <div className="small">{parseFloat(weight).toFixed(1)} kg</div> 
                <div className="small">{parseFloat(theoritical_weight).toFixed(1)} kg</div>
                <div className="small">{shipment_cost} {shipment_currency}</div>
                <div className="small">{parseFloat(cost_per_kg).toFixed(2)} {shipment_currency}</div>
                <div className="small">{invoice_number}</div>
                <div className="small">{invoice_amount && invoice_amount} {invoice_amount && shipment_currency}</div>
                <div className="status">{status === 'pending' && <button onClick={() => handleInvoiceClick(shipment_id)}>Finaliser</button>}{status === 'sent' && <a href={label_url} target="_blank" rel="noopener noreferrer"><button>Imprimer</button></a>}</div>
    </div>
  );
};

export default ShipmentLine;
